<template>
  <div>
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          class="inputx"
          type="file"
          ref="file"
          name="file"
          accept=".xls, .xlsx"
        />
      </div>
    </div> -->
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <!-- <vs-button class="ml-auto mt-2" v-on:click="handleSubmit"
          >Submit</vs-button
        > -->
        <!-- <vs-button
          class="ml-4 mt-2"
          type="border"
          color="warning"
          v-on:click="handleDownload"
        >Download Template</vs-button> -->
      </div>
    </div>

    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <!-- <th width="15%">
              <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
            </th> -->
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.payment"
              v-bind:class="[selectedData.ID == tr.ID ? 'colored' : '', '']"
            >
              <vs-td>
                <template v-if="selectedData.ID != tr.ID">
                  <!-- <vx-tooltip text="Cancel Apply">
                    <vs-button
                      color="red"
                      type="line"
                      @click="cancelApply(tr)"
                      icon-pack="feather"
                      icon="icon-x-square"
                    />
                  </vx-tooltip> -->
                  <vx-tooltip text="Show Apply">
                    <vs-button
                      color="success"
                      type="line"
                      @click.stop="detailPayment(tr)"
                      icon-pack="feather"
                      icon="icon-info"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td> {{ tr.CustomerCode }} {{ tr.CustomerName }} </vs-td>
              <vs-td>
                Code : {{ tr.Code }}
                <br />
                Reference Code : {{ tr.ReferenceCode }}
                <br />
                Payment Type : {{ typePayment[tr.Type] }}
                <br />
                {{ tr.PaymentMethod }} - {{ tr.PaymentName }}
                <br />
                {{ tr.PaymentAccountOwner }} {{ tr.PaymentAccountNumber }}
              </vs-td>
              <vs-td>
                {{ priceFormat(tr.Amount) }}
              </vs-td>
              <vs-td>
                Created Date : {{ dateFormat(tr.CreatedAt) }}
                <br />
                Payment Date : {{ dateFormat(tr.DatePayment) }}
                <br />
                Posting Date : {{ dateFormat(tr.PostingDate) }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <detail @closeDetail="closeDetail" :payment="selectedData" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import {
  dataPayment,
  cancelPaymentLine,
} from "../../../../services/api/payment";
import moment from "moment";
import detail from "./detail_payment.vue";
export default {
  props: {
    selected: Object,
    option: Object,
    isReload: Boolean,
    invoiceCode: {
      type: Array,
      default: () => [],
    },
    paymentMethod: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    detail,
  },
  data() {
    return {
      typePayment: ["", "Trade", "Deposit", "Open Deposit", "Credit Note"],
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
      },
      status: 2,
      bank_id: 1,
      type: 1,
      header: [
        {
          text: "Action",
          sortable: false,
        },
        {
          text: "Customer",
          value: "customer_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Date",
          value: "date_payment",
        },
      ],
      uploadReady: true,
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
    };
  },
  computed: {},
  watch: {
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    detailPayment(selected) {
      this.closeDetail();
      this.selectedData = selected;
      this.detail = true;
      // this.params.payment_id = selected.ID;
      // const paymentLine = getPaymentLine(this.params);
      // paymentLine.then((r) => {
      //   console.log(this.params);
      //   console.log(r);
      // });
    },
    closeDetail(params = false) {
      // window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    handleSubmit() {
      var file = this.$refs.file.files[0];
      if (file == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        if (this.isExcel(file)) {
          //

          this.formData = new FormData();
          this.formData.append("file", file);
          this.$http
            .post("/api/v1/payment/reconcile", this.formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((resp) => {
              if (resp.status == "success") {
                var message = "";
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
                this.uploadReady = false;
                this.$nextTick(() => {
                  this.uploadReady = true;
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: error,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            });
        }
      }
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    },
    cancelApply(selected) {
      this.$vs.loading();
      const params = {
        payment_id: selected.ID,
      };
      const cancelPayment = cancelPaymentLine(params);
      cancelPayment.then((r) => {
        // console.log(r, "ini r");
        this.$vs.notify({
          color: "success",
          title: "success",
          text: "",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.$vs.loading.close();
        this.reloadData(this.params);
      });
    },
    reloadData(params) {
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      const customer_codes = this.$store.getters["customerFilter/getSelectedCustomersCodes"];
      this.params = {
        ...params,
        territory_ids,
        customer_codes,
        type: this.type,
        status: this.status,
        bank_id: this.bank_id,
        invoice_codes: this.invoiceCode,
        methods: this.paymentMethod,
      };
      console.log(this.params);
      this.$vs.loading();
      const dataTable = dataPayment(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.payment;
          this.responseData = r.data;
          this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: absolute;
  left: 4%;
  top: 10%;
  max-width: 100%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
