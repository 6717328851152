<template>
  <div>
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.customer"
          :options="option.customer"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="code"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Payment Account</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.paymentAccount"
          :options="option.paymentAccount"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="code"
          label="AccountName"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >({{ paymentMethod[props.option.Type] }}
                {{ props.option.Issuer }} - {{ props.option.AccountNumber }})
                {{ props.option.AccountName }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >({{ paymentMethod[props.option.Type] }}
                {{ props.option.Issuer }} - {{ props.option.AccountNumber }})
                {{ props.option.AccountName }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Date Payment</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <flat-pickr
          :config="configDatePicker"
          v-model="create.datePayment"
          placeholder="Date Payment"
          style="width: 100%"
        />
      </div>
    </div>
    
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Reference Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="create.referenceCode" />
      </div>
    </div>
    
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Amount</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          v-model="create.amount"
          @keyup="create.amount = formatPrice(create.amount.toString())"
        />
      </div>
    </div>
    <!-- <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Note</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="create.note"></vs-textarea>
      </div>
    </div> -->

    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>

      <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Submit
      </vs-button>
    </div>
  </div>
</template>
<script>
import {
  dataCustomer,
  dataPaymentAccount,
  postPayment,
} from "../../../../services/api/payment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    flatPickr,
  },
  data() {
    return {
      // 1: Real Account,2: VA, 3: Wallet
      paymentMethod: ["", "Real Account", "VA", "Wallet"],
      create: {
        referenceCode: null,
        customer: null,
        paymentAccount: null,
        dateApply:'',
        datePayment:'',
        amount: 0,
        notes: null,
      },
      option: {
        customer: [],
        paymentAccount: [],
      },
      configDatePicker: {
        // altFormat: 'F j, Y',
        // altInput: true,
        dateFormat: 'd F Y',
        
      },
    };
  },
  mounted() {
    this.getCustomer();
  },
  watch: {
    "create.customer": function () {
      this.getPaymentAccount();
      //   cek value
      // console.log(args, this.create.customer)
    },
  },
  methods: {
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    // onFromChange(selectedDates, dateStr, instance) {
      // console.log(instance);
      // this.$set(this.configTodateTimePicker, "minDate", dateStr);
    // },
    handleSubmit() {
      const request = {
        type: 1,
        customer_code: this.create.customer.code,
        customer_name: this.create.customer.name,
        payment_method: this.paymentMethod[this.create.paymentAccount.Type],
        payment_name: this.create.paymentAccount.Issuer,
        payment_account_owner: this.create.paymentAccount.AccountName,
        payment_account_number: this.create.paymentAccount.AccountNumber,
        reference_code: this.create.referenceCode,
        date_apply: this.create.datePayment,
        date_payment: this.create.datePayment,
        amount: parseInt(this.create.amount.replace(/[^,\d]/g, "").toString()),
        status: 0,
      };
      // console.log(request)
      // var i = true
      // if (i) {
      //   return
      // }
      this.$vs.loading();
      const post = postPayment(request);
      post.then((r) => {
        if (r.code == 500) {
          this.$vs.notify({
            title: "Failed",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
        } else if (r.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "success",
          });
        }
        this.$nextTick(() => {
          // this.$emit("closeDetail");
          this.$emit("closeDetail", true);
          this.$vs.loading.close();
        });
      });
    },
    formatPrice(angka, prefix = "") {
      console.log(angka);
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      split[0] = parseInt(split[0]).toString();
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
    getCustomer() {
      this.$vs.loading();
      const customer = dataCustomer();
      customer.then((response) => {
        this.option.customer = [];
        response.data.customer.map((row) => {
          this.option.customer.push(row);
        });
        this.$vs.loading.close();
      });
    },
    getPaymentAccount() {
      this.$vs.loading();
      const paymentAccount = dataPaymentAccount(this.create.customer.code);
      paymentAccount.then((response) => {
        this.create.paymentAccount = null;
        this.option.paymentAccount = [];
        response.data.paymentAccount.map((row) => {
          this.option.paymentAccount.push(row);
        });
        this.$vs.loading.close();
      });
    },
  },
};
</script>