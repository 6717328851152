<template>
  <div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <!-- <th width="15%">
                <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
              </th> -->
          </template>
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
              <vs-td> {{ tr.CustomerCode }} {{ tr.CustomerName }} </vs-td>
              <vs-td>
                Code : {{ tr.Code }}
                <br />
                Reference Code : {{ tr.ReferenceCode }}
                <br />
                Payment Type : {{ typePayment[tr.Type] }}
                <br />
                {{ tr.PaymentMethod }} - {{ tr.PaymentName }}
                <br />
                {{ tr.PaymentAccountOwner }} {{ tr.PaymentAccountNumber }}
              </vs-td>
              <vs-td>
                {{ priceFormat(tr.Amount) }}
              </vs-td>
              <vs-td>
                {{ dateFormat(tr.DatePayment) }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>
<script>
import { dataPayment } from "../../../../services/api/payment";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
    isReload: Boolean,
    invoiceCode: {
      type: Array,
      default: () => [],
    },
    paymentMethod: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      typePayment: ["", "Trade", "Deposit", "Open Deposit", "Credit Note"],
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
      },
      status: 4,
      type: 1,
      header: [
        {
          text: "Customer",
          value: "customer_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Date",
          value: "date_payment",
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
    };
  },
  computed: {},
  watch: {
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    closeDetail(params = false) {
      //   window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    reloadData(params) {
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      const customer_codes = this.$store.getters["customerFilter/getSelectedCustomersCodes"];
      this.params = {
        ...params,
        territory_ids,
        customer_codes,
        status: this.status,
        methods: this.paymentMethod,
        invoice_codes: this.invoiceCode,
      };
      console.log(params);
      this.$vs.loading();
      const dataTable = dataPayment(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.payment;
          this.responseData = r.data;
          this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
